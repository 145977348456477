import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { LANGUAGE_CODE } from 'src/constants';
import * as Styled from './styled';

interface props {
  targetDate: Date;
  setRemainingTime: (number) => void;
}

/**
 * 상품 카드 공통 타이머 컴포넌트 입니다.
 */
export const Timer: React.FC<props> = ({ targetDate, setRemainingTime }) => {
  const { t, i18n } = useTranslation(['product']);
  const [calcDate, setCalcDate] = useState(null);
  // 대상 일자
  const targetTime = targetDate && new Date(targetDate).getTime();
  // 일자 간격
  const [diffState, setDiffState] = useState(targetTime - new Date().getTime());

  useEffect(() => {
    // 띠배너에 표시되는 타이머
    const timer = setTimeout(() => {
      setDiffState(targetTime - new Date().getTime());
      setRemainingTime(targetTime - new Date().getTime());
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  // 날짜 계산
  useEffect(() => {
    setCalcDate(() => {
      // 날짜 계산 단위
      const calcSec = 1000;
      const calcMin = calcSec * 60;
      const calcHour = calcMin * 60;
      const calcDay = calcHour * 24;

      // 남은 일
      const diffDay = Math.floor(diffState / calcDay);
      // 남은 시간
      let diffHour = Math.floor((diffState % calcDay) / calcHour).toString();
      if (diffHour.length < 2) diffHour = '0' + diffHour;
      // 남은 분
      let diffMin = Math.floor((diffState % calcHour) / calcMin).toString();
      if (diffMin.length < 2) diffMin = '0' + diffMin;
      // 남은 초
      let diffSec = Math.floor((diffState % calcMin) / calcSec).toString();
      if (diffSec.length < 2) diffSec = '0' + diffSec;

      return (
        <>
          {/* 1일 이상남은 프로모션은 ~일 붙임. */ diffDay > 0 && `${t('product:timer.remainingTime', { diffDay })}`}

          {/* 영문이면서 2일 이상남은 프로모션은 DAYS 미만인경우엔 DAY */}
          {i18n.language === LANGUAGE_CODE.EN && diffDay >= 2 ? 'S ' : ' '}

          {`${diffHour} : ${diffMin} : ${diffSec}`}
        </>
      );
    });
  }, []);

  return <Styled.TimerText>{calcDate}</Styled.TimerText>;
};
